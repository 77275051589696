<template>
    <span class="BadgePro-module_root BadgePro-module_svg-eA-ov Badge-module_root-73IPW" data-test-id="pro-badge">
<svg width="32px" height="16px" viewBox="0 0 32 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="test" fill-rule="nonzero">
            <rect id="Rectangle" fill="#EDE847" x="0" y="0" width="32" height="16" rx="1.5"></rect>
            <g id="Group" transform="translate(3.000000, 1.000000)" fill="#000000" font-family="HelveticaNeue-Bold, Helvetica Neue" font-size="11" font-weight="bold">
                <text id="NEW">
                    <tspan x="0" y="11">NEW</tspan>
                </text>
            </g>
        </g>
    </g>
</svg>
    </span>
</template>
<style scoped>
.BadgePro-module_root {
    background: var(--cp-color-yellow);
    color: #000!important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: var(--cp-font-family-header-alt);
    text-transform: uppercase;
    font-size: .8rem;
    padding: 0.125rem 0.375rem 0;
    border-radius: 2px;
    background: var(--cp-color-9);
    color: var(--cp-color-20);
    margin-right: 0.5rem;
    display: inline-block;
}
</style>
<script>
// used in joint projects
  export default {
        name: 'NewFeatureTag',
        props: ['title'],
  }
</script>